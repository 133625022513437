const SET_POSTS = "SET_POSTS";
const LOADER = "LOADER";
const SHOWPOPUP = "SHOWPOPUP";
const SET_SLIDER_MAIN = "SET_SLIDER_MAIN";
const SOCIALS = {};

let initialState = {
    mainpost: null,
    loading: null,
    showPopup: false,
    mainSlider: null,
    socials: {}
};

const indexReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POSTS :
            return  {
                ...state,
                mainpost: [...action.posts]
            }
        case SET_SLIDER_MAIN :
            return  {
                ...state,
                mainSlider: action.sliderPostF
            }
        case LOADER:
            return {
                ...state,
                loading: action.loadingBool
            }
        case SHOWPOPUP:
            return {
                ...state,
                showPopup: action.show
            }
        case SOCIALS:
            return {
                ...state,
                socials: action.socials
            }
        default:
            return state
    }
}

export const setPostAc = (posts) => {
    return {
        type: SET_POSTS,
        posts
    }
}
export const setSliderAc = (sliderPostF) => {
    return {
        type: SET_SLIDER_MAIN,
        sliderPostF
    }
}

export const setLoading = (loadingBool) => {
    return {
        type: LOADER,
        loadingBool
    }
};

export const setShowAc = (show) => {
    return {
        type: SHOWPOPUP,
        show
    }
};
export const setSocials = (socials) => {
    return {
        type: SOCIALS,
        socials
    }
};

export default indexReducer;