import React from "react";
import {connect} from "react-redux";
import {setShowAc} from "../../../redux/indexReducers";
import style from "../../Mainpage/Mainpage.module.css";
import {Field} from "redux-form";
import {CheckBox, Input, TextArea} from "../utils/controls/input";
import {maxLength30, required, requiredCheckBox} from "../utils/validators/validators";
import {Fade} from "react-reveal";
import ContainerModal from "../Modal/ModalContainer";

class ContactForm extends React.Component {
    // modal() {
    //   this.props.setShowAc(true);
    // }

    render() {
        return (
            <div className="row mt-5">
            {this.props.showPopup ? <ContainerModal/> : null}
                <div className="col-12">
                    <Fade>
                        <form onSubmit={this.props.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <Field name="name" type="text" component={Input}
                                           placeholder="Имя" className={style.input}
                                           validate={[required, maxLength30]}
                                    />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <Field name="phone" type="text" component={Input}
                                           placeholder="Телефон" className={style.input}
                                           validate={[required]}
                                    />
                                </div>
                                <div className="col-12">
                                    <Field name="email" type="text" component={Input}
                                           placeholder="Почта" className={style.input}
                                           validate={[required]}/>
                                </div>
                                <div className="col-12">
                                    <Field name="text" component={TextArea}
                                           className={style.textarea} cols="30"
                                           placeholder="Сообщение" rows="3"/>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                                        <span>
                                                        <label className="mb-0 d-flex">
                                                            <Field className="mr-3" name= "rules" component={CheckBox}
                                                                   type="checkbox"
                                                                   validate={[requiredCheckBox]}
                                                                   onClick={() => this.props.setShowAc}
                                                            />

                                                        </label>
                                                    </span>
                                        <button className={style.button_submit}
                                                type="submit">Отправить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Fade>
                </div>
            </div>
        )
    }
}

//export default ContactForm;

const mapStateToProps = (state) => {
    return {
        showPopup: state.indexReducer.showPopup,
    }
}

const ConnectContactForm = connect(mapStateToProps, {setShowAc})(ContactForm)

export default ConnectContactForm;