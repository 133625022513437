const SET_POJECT = "SET_POJECT";
const LOADER = "LOADER";
const SET_FILTER = "SET_FILTER";
const SET_CATEGORY = "SET_CATEGORY";

let initialState = {
    projects: [],
    catrgories: [],
    loading: null,
    activeFilter: 1,
};

const ProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POJECT:
            return {
                ...state,
                projects: [...action.getProjects]
            }
        case SET_CATEGORY:
            return {
                ...state,
                catrgories: [...action.catrgories]
            }
        case LOADER:
            return {
                ...state,
                loading: action.loadingBool
            }
        case SET_FILTER:
            return {
                ...state,
                activeFilter: action.cat
            }
        default:
            return state
    }
}

export const setProject = (getProjects) => {
    return {
        type: SET_POJECT,
        getProjects
    }
};
export const setCategory = (catrgories) => {
    return {
        type: SET_CATEGORY,
        catrgories
    }
};
export const setLoading = (loadingBool) => {
    return {
        type: LOADER,
        loadingBool
    }
};

export const setFilter = (cat) => {
    return {
        type: SET_FILTER,
        cat
    }
};


export default ProjectReducer;