import React from "react";
import axios from "axios";
import {connect} from "react-redux";
import {setSocials} from "../../../redux/indexReducers";

class SocialList extends React.Component {

  // получаем ссылки на соц сети из WP
  componentDidMount() {
    if (this.props.socials != undefined) {
      if (!Object.keys(this.props.socials).length) {
        axios.get("https://dzendom.su/api/wp-json/grandit/v1/social-groups/")
          .then(response => {
            response.data.fb = response.data.fb.length ? response.data.fb : '#';
            response.data.tw = response.data.tw.length ? response.data.tw : '#';
            response.data.viber = response.data.viber.length ? response.data.viber : '#';
            response.data.yt = response.data.yt.length ? response.data.yt : '#';
            response.data.in = response.data.in.length ? response.data.in : '#';
            
            this.props.setSocials(response.data);
          }).catch(err => {
            let socials = {
              fb: '#',
              tw: '#',
              viber: '#',
              yt: '#',
              in: '#'
            }

            this.props.setSocials(socials);
          });
      }
    }
  }

  render() {
    return (null)
  }

}

const mapStateToProps = (state) => {
    return {
        socials: state.indexReducer.socials,
    }
}

const ConnectContainer = connect(mapStateToProps, {setSocials})(SocialList)

export default ConnectContainer;