import React from "react";
import MainPage from "./Mainpage";
import {connect} from "react-redux";
import {setLoading, setPostAc, setShowAc, setSliderAc} from "../../redux/indexReducers";
import axios from "axios";
import {setMenuAc} from "../../redux/ModalReducer";

class MainpageContainer extends React.Component {
    componentDidMount() {
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=6")
            .then(response => {
                this.props.setLoading(false);
                this.props.setPostAc(response.data);
                this.props.setLoading(true);
            });
        axios.get("https://dzendom.su/api/wp-json/acf/v3/options/options")
            .then(response => {
                this.props.setSliderAc(response.data.acf.slajder)
            })
    }

    render() {
        return <MainPage state={this.props.state}
                         loading={this.props.loading}
                         showPopup={this.props.showPopup}
                         setShowAc={this.props.setShowAc}
                         showMenu={this.props.showMenu}
                         setMenuAc={this.props.setMenuAc}
                         mainSlider={this.props.mainSlider}
        />

    }
}

const mapStateToProps = (state) => {
    return {
        state: state.indexReducer.mainpost,
        loading: state.indexReducer.loading,
        showPopup: state.indexReducer.showPopup,
        showMenu: state.ModalReducer.showMenu,
        mainSlider: state.indexReducer.mainSlider,

    }
}
const ConnectContainer = connect(mapStateToProps, {setPostAc, setLoading, setShowAc, setMenuAc, setSliderAc})(MainpageContainer)
export default ConnectContainer;