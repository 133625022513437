import React from "react";
import {reset, reduxForm} from "redux-form";
import ContactForm from "./ContactForm";
import axios from "axios";

const ReduxFormContactSingle = reduxForm({
    form: "SingleForm"
})(ContactForm)

const SingleFormHOC = (props) => {
    const onSubmit = (formData, dispatch) => {
        let name = formData.name,
            phone = formData.phone,
            email = formData.email,
            text = formData.text;


        //axios.get(`https://dzendom.su/mail.php?name=${name}&phone=${phone}&email=${email}&text=${text}`)

        /*fetch('https://dzendom.su/api/wp-admin/admin-ajax.php', {
          method: "POST",
          credentials: 'same-origin',
          body: JSON.stringify({
            action: 'grandit_send_mail',
            data: formData
          }),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
          },
        })*/
        fetch('https://dzendom.su/mail.php', {
          method: "POST",
          credentials: 'include',
          body: JSON.stringify({formData}),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
            .then(response => response.json())
            .then((result) => {
                if (result.send === true) {
                    dispatch(reset('SingleForm'));
                    alert("Сообщение успешно отправлено");
                } else {
                    console.log(result.msg);
                }
            })
            .catch((error) => {
                alert("Ошибка")
            });
    }
    return (
        <ReduxFormContactSingle reset={props.reset} onSubmit={onSubmit}/>
    )
}

export default SingleFormHOC;

