import React from "react";
import style from "./Modal.module.css";
import close from "../../../assets/image/close.svg";
import axios from "axios";

const renderHTML = (rawHTML: string) => React.createElement("div", {
  className: style.text_personal_data,
  dangerouslySetInnerHTML: { __html: rawHTML }
});

class Modal extends React.Component {

    state = {
      text_personal_data: ''
    };

    componentDidMount() {
      // выбираем текст блока "Контакт" из админки WP
      axios.get("https://dzendom.su/api/wp-json/wp/v2/pages/425").then(response => {
        this.setState({text_personal_data: response.data.content.rendered});
      });
    }

    render() {
        return (
            <>
                <div className={style.modal_wrapper}>
                    <div className={style.first_block}>
                      {renderHTML(this.state.text_personal_data)}
                    </div>
                    <div onClick={() => this.props.setShowAc(false)} className={style.close}>
                        <img width="30px" height="30px" src={close} alt=""/>
                    </div>
                </div>
            </>
        );
    }
}

export default Modal;