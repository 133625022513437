import React from "react";
import {connect} from "react-redux";
import Singleproject from "./Singleproject";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {setLoading, setPostAc} from "../../redux/SingleReducers";
import {setMenuAc} from "../../redux/ModalReducer";

class SingleContainer extends React.Component {
    componentDidMount() {

       // axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=6")
       //      .then(response =>{
       //         response = response.data.filter(item => item.title.rendered.match(/\d+/g).join('') == this.props.match.params.postId)
       //         return response[0]
       //      })
       //      .then(response => {
       //          axios.get("https://dzendom.su/api/wp-json/wp/v2/posts/" + response.id)
       //          .then(response => {
       //              this.props.setLoading(false);
       //              this.props.setPostAc(response.data);
       //              this.props.setLoading(true);
       //          });              
       //      })
            


        let postId = this.props.match.params.postId; 
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?slug=" + postId)
            .then(response => {
                this.props.setLoading(false);
                this.props.setPostAc(response.data[0]);
                this.props.setLoading(true);
                console.log(response.data.slug)
            });

    }
    render() {
        return <Singleproject state={this.props.state} loading={this.props.loading}/>
    }
}

const mapStateToPropsToProps = (state) => {
    return {
        state: state.SingleReducer.post,
        loading: state.SingleReducer.loading

    }
};

let WithurlData = withRouter(SingleContainer);
const ContainerSingles = connect(mapStateToPropsToProps, {setPostAc, setMenuAc, setLoading})(WithurlData);


export default ContainerSingles;