import React from 'react';
import './App.css';
import Favicon from './pages/Header/Favicon.jsx';
import SocialList from './pages/Elements/SocialList/SocialList.jsx';
import Footer from "./pages/Footer/Footer";
import {Route} from "react-router-dom";
import ContainerSingle from "./pages/SingleProject/SingleProgectContainer";
import {ContainerProjectConnect} from "./pages/Project/Project.Container";
import ConnectContainer from "./pages/Mainpage/MainpageContainer";
import {Fade} from "react-reveal";

function App() {
    return (
        <>
            <Favicon/>
            <SocialList/>
                <Route exact path="/" render={() => <ConnectContainer/>}/>
                <Route path="/project" render={() => <ContainerProjectConnect/>}/>
                <Route exact path="/projects/:postId?" render={() => <ContainerSingle/>}/>
            <Footer/>
        </>
    );
}

export default App;
