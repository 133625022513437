import React from "react";
import {connect} from "react-redux";
import HeaderSingle from "./HeaderSingle";
import {setMenuAc} from "../../redux/ModalReducer";


class HeaderSingleContainer extends React.Component{
    render() {
        return (
            <HeaderSingle {...this.props} setMenuAc={this.props.setMenuAc}/>
        )
    }
}
const mapDispatchToProps = (state) => {
    return {
        showMenu: state.ModalReducer.showMenu,
    }
}
const HeaderSingleContainerConnect = connect(mapDispatchToProps, {setMenuAc})(HeaderSingleContainer);
export default HeaderSingleContainerConnect;
