import React from "react";
import {connect} from "react-redux";
import Header from "./Header";
import {setMenuAc} from "../../redux/ModalReducer";


class HeaderContainer extends React.Component{
    render() {
        return (
            <Header {...this.props} setMenuAc={this.props.setMenuAc}/>
        )
    }
}
const mapDispatchToProps = (state) => {
    return {
        showMenu: state.ModalReducer.showMenu,
    }
}
const HeaderContainerConnect = connect(mapDispatchToProps, {setMenuAc})(HeaderContainer);
export default HeaderContainerConnect;
