import React from "react";
import axios from "axios";
import {connect} from "react-redux";
import {setFilter, setLoading, setProject, setCategory} from "../../redux/ProjectReducer";
import Project from "./Project";
import {setMenuAc} from "../../redux/ModalReducer";
//
import {setShowAc} from "../../redux/indexReducers";


class ContainerProject extends React.Component {
    componentDidMount() {
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=100&&categories=" + this.props.activeFilter)
            .then(response => {
                this.props.setLoading(false);
                this.props.setProject(response.data);
                this.props.setLoading(true);
            });
            axios.get("https://dzendom.su/api/wp-json/wp/v2/categories")
            .then(response => {
                function sortByAge(arr) {
                    arr.sort((a, b) => a.id > b.id ? 1 : -1);
                  }
                sortByAge(response.data)
                this.props.setCategory(response.data);
            })
    }

    render() {
        return (
            <Project {...this.props} activeFilter={this.props.activeFilter}
                     loading={this.props.loading}
                     setProject={this.props.setProject}
                     setFilter={this.props.setFilter}
                     setMenuAc={this.props.setMenuAc}
                     showMenu={this.props.showMenu}
                     //
                     setShowAc={this.props.setShowAc}

                     />
        )
    }
}

const mapStateToPropsToProps = (state) => {
    return {
        state: state.ProjectReducer,
        loading: state.ProjectReducer.loading,
        activeFilter: state.ProjectReducer.activeFilter,
        showMenu: state.ModalReducer.showMenu,
        //
        showPopup: state.indexReducer.showPopup,

    }
};
export const ContainerProjectConnect = connect(mapStateToPropsToProps, {
    setProject,
    setLoading,
    setFilter,
    setMenuAc,
    setCategory,
    //
    setShowAc

})(ContainerProject);
