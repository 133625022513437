const GET_POST = "GET_POST";
const GET_POSTS = "GET_POSTS";
const SET_LOADER = "SET_LOADER";

let initialState = {
    post: [],
    postsForSlider: [],
    loading: null
}

const SingleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POST:
            return {
                ...state,
                post: { ...action.post }
            }
        case SET_LOADER:
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state
    }
}

export const setPostAc = (post) => {
    return {
        type: GET_POST,
        post
    }
}

export const setLoading = (loading) => {
    return {
        type: SET_LOADER,
        loading
    }
}

export default SingleReducer;