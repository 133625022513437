import React from "react";
import MetaTags from 'react-meta-tags';
import Carousel from "react-bootstrap/Carousel";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";
import { Element, Link } from 'react-scroll';
import bar from "../../assets/bar-white.svg";
import logo from "../../assets/logo_white.svg";
import people from "../../assets/image/peolps.png";
import scroll from "../../assets/scroll.png";
import FormHOC from "../Elements/Contact/ContactContainer";
import ContainerModal from "../Elements/Modal/ModalContainer";
import ContainerMenuModal from "../Elements/ModalMenu/ModalMenuContainer";
import style from "./Mainpage.module.css";
import axios from "axios";

const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class MainPage extends React.Component {

    state = {
      about_text: '',
      meta_title: '',
      meta_description: '',
      main_team_src: '',
      main_team_video: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        // выбираем мета теги из админки WP
        axios.get("https://dzendom.su/api/wp-json/grandit/v1/meta-tags").then(response => {
          this.setState({
            meta_title: response.data.title,
            meta_description: response.data.description
          });
        });

        // выбираем текст "About" из админки WP
        axios.get("https://dzendom.su/api/wp-json/wp/v2/pages/344").then(response => {
          this.state.about_text = response.data.content.rendered;
        });

        // выбираем путь к картинке или к видео с youtube из админки wp
        axios.get("https://dzendom.su/api/wp-json/grandit/v1/get-main-bg/").then(response => {
          this.setState({
            main_team_src: response.data.img,
            main_team_video: response.data.video
          });
        });
    }

    Projects() {
        let projectPost = this.props.state;
        return projectPost && projectPost.map(el => {
            return (
                <div className="col-lg-4 col-md-6 col-12 mb-2 p-0 pr-md-3 pl-md-3" key={el.id}>
                    <Fade>
                        <NavLink to={"projects/" + el.slug} className={style.wrapper_card_project}>
                            <img className="img-fluid" src={el.fimg_url} alt=""/>
                            <p className={style.title_card}>{el.title.rendered}</p>
                            <p className={style.small_info}>{el.acf.ploshhad_obekta.ploshhad_obekta_value} м.кв</p>
                            <div className={style.hover_wrapper}>
                                <div className={style.wrapper_card_ob}>
                                    <span className={style.hower_wrapper_span}>
                                        {el.acf.price.includes('₽') ? el.acf.price : `${el.acf.price} ₽`}
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                    </Fade>
                </div>
            )
        })
    }


    ControlledCarousel() {
        return (
            <div className={style.bg_slide}>
                {this.props.mainSlider ?
                    <div id="sliders">
                        <Fade>
                        <Carousel indicators={false} interval={null}>
                            {
                                this.props.mainSlider && this.props.mainSlider.map((el, index) => {
                                    const mobile = el.foto_dlya_mobilnoj_versii.url;
                                    const pc = el.foto_slider.url;
                                    const windowWidths = window.innerWidth;
                                    let divStyle;
                                    if (windowWidths < 768) {
                                        divStyle = {
                                            backgroundImage: 'url(' + mobile + ')',
                                        }
                                    } else {
                                        divStyle = {
                                            backgroundImage: 'url(' + pc + ')',
                                        }
                                    }

                                    return (
                                        <Carousel.Item key={el.foto_slider.id || "key"}>
                                            <div id="first_slide" className={style.first_slide} style={divStyle}>
                                                <div className="container">
                                                    <div className="row margin-top_content">
                                                        <div className="col-12">
                                                            <h2 className={style.h2}>{el.zagolovok}</h2>
                                                            <p className={style.text_slider}>{el.opisanie}</p>
                                                            <div className={style.price}><span
                                                                className={style.maincarousel_small_price}>от</span>
                                                                <span className={style.maincarousel_price_text}>{el.czena}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={style.numbers}>
                                                    <span className={style.numbers_big}>0{index + 1}</span> <span
                                                    className={style.numbers_small}>/ 0{this.props.mainSlider && this.props.mainSlider.length}</span>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                        </Fade>
                    </div> : null
                }
            </div>
        );
    }


    render() {
        return (
            <div className={style.wrapper_page_content}>
                <MetaTags>
                  <title>{this.state.meta_title}</title>
                  <meta name="description" content="{this.state.meta_description}" />
                  <meta property="og:title" content="{this.state.meta_title}" />
                </MetaTags>
                <div className={"container"}>
                    <div className={style.border__right}></div>
                </div>
                {this.props.showPopup ? <ContainerModal/> : null}
                <ContainerMenuModal/>
                <div className={style.nav_fixed}>
                    <div className={style.logo}>
                        <a href="https://dzendom.su/"><img src={logo} alt=""/></a>
                    </div>
                    <div className={style.dotted}>
                        <Link activeClass="dotter_border-active" className="dotter_border" to="test1" spy={true}
                              smooth={true} offset={0} duration={500} onSetActive={this.handleSetActive}>
                            <div className="dotted_white"/>
                        </Link>
                        <Link activeClass="dotter_border-active" className="dotter_border" to="test2" spy={true}
                              smooth={true} offset={0} duration={500} onSetActive={this.handleSetActive}>
                            <div className="dotted_white"/>
                        </Link>
                        <Link activeClass="dotter_border-active" className="dotter_border" to="test3" spy={true}
                              smooth={true} offset={0} duration={500} onSetActive={this.handleSetActive}>
                            <div className="dotted_white"/>
                        </Link>
                        <Link activeClass="dotter_border-active" className="dotter_border" to="test4" spy={true}
                              smooth={true} offset={0} duration={500} onSetActive={this.handleSetActive}>
                            <div className="dotted_white"/>
                        </Link>
                    </div>
                    <div>
                        <div className={style.right__scroll}><img src={scroll} alt=""/></div>
                    </div>
                </div>
                <div className={style.nav_fixed_right}>
                    <div onClick={() => this.props.setMenuAc(true)}>
                        <img className={style.logo_right} src={bar} alt=""/>
                    </div>

                    <div></div>
                </div>
                <Element name="test1" className="element">
                    <div className={style.header_container}>
                        <div className="container">
                            <div className={style.navigation}>
                                <ul className={style.d_flex_i}>
                                    <li className={style.links}>
                                        <NavLink activeClassName="active_link_menu" to="/">Главная</NavLink>
                                    </li>
                                    <li className={style.links}>
                                        <NavLink activeClassName="active_link_menu" to="/project">Проекты</NavLink>
                                    </li>
                                    <li className={style.links}>
                                        <a href="/#filosofy">Философия</a>
                                    </li>
                                    <li className={style.links}>
                                        <a href="/#contacts">Контакты</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {this.ControlledCarousel()}
                </Element>

                <div className={style.second_slide}>
                    <div className="container">
                        <Element name="test2" className="element2">
                            <div className={"row " + style.non_mobile_el}>
                                {this.Projects()}
                            </div>
                            <div className="col-12 text-center p-0 p-md-2">
                                <Fade> <NavLink to="/project" className={style.button}>Все проекты</NavLink></Fade>
                            </div>
                        </Element>
                    </div>
                </div>
                <div className={style.second_slide_cust}>
                    <div className="container">
                        <Element name="test3" className="element3">
                            <div className="row">
                                <div className="col-12">
                                    <Fade>
                                        {this.state.main_team_src &&
                                        <div className={style.bigImage}>
                                          <img src={this.state.main_team_src} className="img-fluid" alt=""/>
                                        </div>
                                        }
                                        {this.state.main_team_video &&
                                        <div className={style.videoBackground}>
                                          <div className={style.videoForeground}>
                                            <iframe src={'//www.youtube.com/embed/' + this.state.main_team_video + '?autoplay=1&controls=0&fs=0&loop=1&playlist=' + this.state.main_team_video} frameBorder="0" allowFullScreen></iframe>
                                          </div>
                                        </div>
                                        }
                                    </Fade>
                                </div>
                            </div>
                        </Element>
                    </div>
                </div>

                <Element name="test4" className="element4">
                    <div id="filosofy" className={style.three_slide}>
                        <div className="container">
                            <div className={style.three_slide_color_wrapper}>
                                <div className={style.wrapper_about_text}>
                                    <h3 className={style.big_text}><Fade bottom>ABOUT</Fade></h3>
                                    <Fade bottom><div className={style.about_text_p}>{renderHTML(this.state.about_text)}</div></Fade>
                                    <Fade bottom> <FormHOC/></Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
            </div>
        )
    }
}


export default MainPage;
