import React from "react";
import MetaTags from 'react-meta-tags';
import style from "./Singleproject.module.css";
import {NavLink} from "react-router-dom";
import SingleFormHOC from "../Elements/ContactForm/ContactFormContainer";
import Fade from 'react-reveal/Fade';
import Loader from "../Elements/Loader/Loader";
import Carousel from "react-bootstrap/Carousel";
import ContainerSimpleSlider from "../Elements/SimpleSlider/ContainerSimpleSlider";
import HeaderSingleContainerConnect from "../HeaderSingle/HeaderSingleContainer";
import axios from "axios";

const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class Singleproject extends React.Component {

    state = {
      meta_title: '',
      meta_description: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        // выбираем мета теги из админки WP
        axios.get("https://dzendom.su/api/wp-json/grandit/v1/meta-tags").then(response => {
          this.setState({
            meta_title: response.data.title,
            meta_description: response.data.description
          });
        });
    }

    render() {
        const state = this.props.state;
        const acf = this.props.state.acf;
        let izo = null;
        let izoTwo = null;
        let izoThree = null;
        if (acf && acf.izobrazheniya) {
            izo = acf.izobrazheniya.map(el => {
                const divStyle = {
                    backgroundImage: 'url(' + el.url + ')'
                };
                return (
                    <Carousel.Item key={el.id} className={style.heght_slider}>
                        <div className={style.bgimage} style={divStyle}/>
                    </Carousel.Item>
                )
            })
        }
        if (acf && acf.izobrazheniyaTwo) {
            izoTwo = acf.izobrazheniyaTwo.map(el => {
                const divStyle = {
                    backgroundImage: 'url(' + el.url + ')'
                };
                return (
                    <Carousel.Item key={el.id} className={style.heght_slider}>
                        <div className={style.bgimage} style={divStyle}/>
                    </Carousel.Item>
                )
            })
        }
        if (acf && acf.izobrazheniyaThree) {
            izoThree = acf.izobrazheniyaThree.map(el => {
                const divStyle = {
                    backgroundImage: 'url(' + el.url + ')'
                };
                return (
                    <Carousel.Item key={el.id} className={style.heght_slider}>
                        <div className={style.bgimage} style={divStyle}/>
                    </Carousel.Item>
                )
            })
        }

        return (
            <div className={style.background_project} key={state.id}>
                <MetaTags>
                  <title>{this.state.meta_title}</title>
                  <meta name="description" content="{this.state.meta_description}" />
                  <meta property="og:title" content="{this.state.meta_title}" />
                </MetaTags>
                <HeaderSingleContainerConnect/>
                {this.props.loading ?
                    <div>
                        <div className="container" id="single">

                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="breadcrumbs mt-2 mb-3">
                                        <div className="d-flex">
                                            <Fade> <span className={style.link}><NavLink
                                                to="/">Главная </NavLink></span></Fade>
                                            <Fade left><span className="ml-1 mr-1">/</span></Fade>
                                            <Fade left><span className={style.link}><NavLink
                                                to="/project">Проекты </NavLink></span></Fade>
                                            <Fade left><span className="ml-1 mr-1">/</span></Fade>
                                            <Fade left><span
                                                className={style.link}>{state && state.title.rendered}</span></Fade>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {izo ?
                            <div className="row mb-3">
                                <div className={'col-12 ' + style.slider_class} id="sliders">
                                    <Carousel indicators={false} interval={null}>
                                        {state && izo}
                                    </Carousel>
                                </div>
                            </div>:
                                null}

                            {izoTwo ?
                            <div className="row mb-3" id="sliders">
                                <div className={'col-12 ' + style.slider_class}>
                                     <Carousel indicators={false} interval={null}>
                                        {state && izoTwo}
                                    </Carousel>
                                </div>
                            </div>
                                : null }
                            {izoThree ?
                            <div className="row mb-3" id="sliders">
                                <div className={'col-12 ' + style.slider_class}>
                                    <Carousel indicators={false} interval={null}>
                                        {state && izoThree}
                                    </Carousel>
                                </div>
                            </div> :
                                null}

                            <div className="row mt-5">
                                <div className="col-lg-6 col-12">
                                    <div className={style.wrap__content}>
                                        <h1 className={style.h1}>{state && acf.where_house}</h1>
                                        {acf.status_proekta.status_proekta_value.length > 0 &&
                                        <p>{state && acf.status_proekta.status_proekta_name}: <b>{state && acf.status_proekta.status_proekta_value}</b></p>
                                        }
                                    </div>

                                    <div className= {`mt-4 ${(window.innerWidth > 425) ? 'text-justify' : 'text-center'}`}>
                                        {state && renderHTML(acf.text_left)}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-12">
                                    <div className={"d-flex justify-content-between " + style.wrap__content}>
                                        <div className="text-center">
                                            <p className={style.block_info}>{state && acf.ploshhad_obekta.ploshhad_obekta_value}</p>
                                            <p>{acf.ploshhad_obekta.ploshhad_obekta_name}</p>
                                        </div>

                                        <div className="text-center">
                                            <p className={style.block_info}>{state && acf.kolichestvo_etazhej.kolichestvo_etazhej_value}</p>
                                            <p>{acf.kolichestvo_etazhej.kolichestvo_etazhej_name}</p>
                                        </div>

                                        <div className="text-center">
                                            <p className={style.block_info}>{state && acf.kolichestvo_komnat.kolichestvo_komnat_value}</p>
                                            <p>{state && acf.kolichestvo_komnat.kolichestvo_komnat_name}</p>
                                        </div>

                                        <div className="text-center">
                                            <p className={style.block_info}>{state && acf.god_proektirovaniya.god_proektirovaniya_value}</p>
                                            <p>{state && acf.god_proektirovaniya.god_proektirovaniya_name}</p>
                                        </div>
                                    </div>
                                    <div className= {`mt-4 ${(window.innerWidth > 425) ? 'text-justify' : 'text-center'}`}>
                                        {state && renderHTML(acf.text_right)}
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 col-12">
                                    <p className={style.price}>{state && acf.price.includes('₽') ? acf.price : `${acf.price} ₽`}</p>
                                </div>

                                <div className="col-lg-6 col-12">
                                    <SingleFormHOC/>
                                </div>
                            </div>


                            <div className="row mt-5 mb-3">
                                <div className="col-12">
                                    <h3 className={style.h3}>Похожие проекты</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-11 m-auto mt-5">
                                    <ContainerSimpleSlider />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 text-center">
                                    <NavLink to="/project" className={style.button}>Все проекты</NavLink>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <Loader/>
                }
            </div>
        )
    }

}

export default Singleproject;