import React from "react";
import {reduxForm} from "redux-form";
import Contact from "./Contact";
import axios from "axios";
import {reset} from 'redux-form';

const ReduxFormContact = reduxForm({
    form: "Contact"
})(Contact)

const FormHOC = (props) => {
    const onSubmit = (formData, dispatch) => {
        let name = formData.name,
            phone = formData.phone,
            email = formData.email,
            text = formData.text;

        //axios.get(`http://mailler.mcdir.ru/mail.php?name=${name}&phone=${phone}&email=${email}&text=${text}`)

        /*fetch('https://dzendom.su/api/wp-admin/admin-ajax.php', {
          method: "POST",
          data: {
            action: 'grandit_send_mail',
            data: formData
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })*/
        fetch('https://dzendom.su/mail.php', {
          method: "POST",
          credentials: 'include',
          body:  JSON.stringify({formData}),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
            .then(response => response.json())
            .then((result) => {
                if (result.send === true) {
                    dispatch(reset('Contact'));
                    alert("Сообщение успешно отправлено");
                } else {
                    console.log(result.msg);
                }
            })
            .catch((error) => {
                alert("Ошибка")
            });
    }
    return (
        <ReduxFormContact onSubmit={onSubmit}/>
    )
}

export default FormHOC;