import React from "react";
import style from "../../Mainpage/Mainpage.module.css";
import {connect} from "react-redux";
import {setShowAc} from "../../../redux/indexReducers";
import {Field} from "redux-form";
import {maxLength30, required, requiredCheckBox} from "../utils/validators/validators";
import {Fade} from "react-reveal";
import {CheckBox, Input, TextArea} from "../utils/controls/input";
import axios from "axios";
//
import ContainerModal from "../Modal/ModalContainer";

const renderHTML = (rawHTML: string) => React.createElement("div", {
  className: style.maincontact_block,
  dangerouslySetInnerHTML: { __html: rawHTML }
});

class Contact extends React.Component {
    state = {
      block_contact_text: ''
    };

    componentDidMount() {
      // выбираем текст блока "Контакт" из админки WP
      axios.get("https://dzendom.su/api/wp-json/wp/v2/pages/371").then(response => {
        this.state.block_contact_text = response.data.content.rendered;
      });
    }

    // modal = () => {
    //   this.props.setShowAc(true);
    // }


    render() {
        return (
            <div className="row mt-5">
            {this.props.showPopup ? <ContainerModal/> : null}
                <div className="col-lg-6 col-12">
                    <Fade>
                      {renderHTML(this.state.block_contact_text)}
                    </Fade>
                </div>
                <div className="col-lg-6 col-12">
                    <Fade>
                        <form onSubmit={this.props.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <Field name="name" type="text" component={Input}
                                           placeholder="Имя" className={style.input}
                                           validate={[required, maxLength30]}
                                    />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <Field name="phone" type="text" component={Input}
                                           placeholder="Телефон" className={style.input}
                                           validate={[required]}
                                    />
                                </div>
                                <div className="col-12">
                                    <Field name="email" type="text" component={Input}
                                           placeholder="Почта" className={style.input}
                                           validate={[required]}/>
                                </div>
                                <div className="col-12">
                                    <Field name="text" component={TextArea}
                                           className={style.textarea} cols="30"
                                           placeholder="Сообщение" rows="3"/>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                                        <span>

                                                        <label className="mb-0 d-flex">
                                                            <Field className="mr-3" name="rules" component={CheckBox}
                                                                   type= "checkbox"
                                                            validate={[requiredCheckBox]}
                                                            onClick={() => this.props.setShowAc}
                                                            />
                                                        </label>
                                                    </span>
                                        <button className={style.button_submit}
                                                type="submit">Отправить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Fade>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showPopup: state.indexReducer.showPopup,
    }
}

const ConnectContact = connect(mapStateToProps, {setShowAc})(Contact)

export default ConnectContact;