import React from "react";
import style from "./Modal.module.css";
import logo from "../../../assets/logo_b.svg";
import close from "../../../assets/X.svg";
import {NavLink} from "react-router-dom";
import {HashLink as Link} from "react-router-hash-link";
import SocialList from "../SocialList/SocialListContainer.jsx";

class ModalMenu extends React.Component {
    closePopup() {
        this.props.setMenuAc(false)
    }

    closePopupAndSETFilterEko() {
        this.props.setFilter(12)
        this.props.setMenuAc(false);
    }

    closePopupAndSETFilterARCH() {
        this.props.setFilter(10)
        this.props.setMenuAc(false);
    }

    closePopupAndSETFilterCot() {
        this.props.setFilter(10)
        this.props.setMenuAc(false);
    }

    closePopupAndSETFilterUyut() {
        this.props.setFilter(10)
        this.props.setMenuAc(false);
    }


    render() {
        let eko = this.props.eko;
        let arch = this.props.arch;
        /**/
        let uyut = this.props.uyut;
        let cot = this.props.cot;
        console.log(uyut)
        let date = new Date;
        let year = date.getFullYear();
        return (
            <div className={style.wrapper + " " + (this.props.showMenu ? style.animated : "")}>
                <div className={style.wrap_modal}>
                    <div className={style.left}>

                    </div>

                    <div className={style.center}>
                        <div>
                            <img onClick={() => this.closePopup()} width="40px" src={logo} alt=""/>
                        </div>
                        <div>
                            <ul className={style.ul}>
                                <li onClick={() => this.closePopup()} className={style.links}>
                                    <NavLink to="/">Главная</NavLink>
                                </li>
                                <li onClick={() => this.closePopup()} className={style.links}>
                                    <NavLink to="/project">Проекты</NavLink>
                                </li>
                                {(eko!= null && eko.length > 0) &&
                                    <li onClick={() => this.closePopupAndSETFilterEko()} className={style.links}>
                                        <NavLink to="/project">Эко</NavLink>
                                    </li>
                                }
                                {eko && eko.map(el => {
                                    return (
                                        <li className={style.link_for} key={el.id}
                                            onClick={() => this.closePopup()}><a
                                        href={"/projects/" + el.slug}>{el.title.rendered}</a></li>
                                    )
                                })}
                                {(arch!= null && arch.length > 0) &&
                                    <li onClick={() => this.closePopupAndSETFilterARCH()} className={style.links}>
                                        <NavLink to="/project">Архитектура</NavLink>
                                    </li>
                                }

                                {arch && 
                                    arch.map(el => {
                                    return (
                                        <li className={style.link_for} key={el.id}
                                            onClick={() => this.closePopup()}><a
                                            href={"/projects/" + el.slug}>{el.title.rendered}</a></li>
                                    )
                                })}

                                {(uyut!= null && uyut.length > 0) &&
                                    <li onClick={() => this.closePopupAndSETFilterCot()} className={style.links}>
                                        <NavLink to="/project">Территория уюта</NavLink>
                                    </li>
                                }

                                {uyut && 
                                    uyut.map(el => {
                                    return (
                                        <li className={style.link_for} key={el.id}
                                            onClick={() => this.closePopup()}><a
                                            href={"/projects/" + el.slug}>{el.title.rendered}</a></li>
                                    )
                                })}                                

                                {(cot!= null && cot.length > 0) &&
                                    <li onClick={() => this.closePopupAndSETFilterCot()} className={style.links}>
                                        <NavLink to="/project">Коттеджные поселки</NavLink>
                                    </li>
                                }

                                {cot && 
                                    cot.map(el => {
                                    return (
                                        <li className={style.link_for} key={el.id}
                                            onClick={() => this.closePopup()}><a
                                            href={"/projects/" + el.slug}>{el.title.rendered}</a></li>
                                    )
                                })}
                                <li onClick={() => this.closePopup()} className={style.links}>
                                    <Link to="/#filosofy">Философия</Link>
                                </li>
                                <li onClick={() => this.closePopup()} className={style.links}>
                                    <Link to="/#contacts">Контакты</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={style.right}>
                        <div onClick={() => this.closePopup()} className={style.close}>
                            <div className={style.top_close}>
                                <img className={style.close_img} width="30px" height="30px" src={close} alt=""/>
                            </div>
                            <SocialList css={style.social_icons}/>

                            <div className={style.bottom}>
                                {year}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalMenu;