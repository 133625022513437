export const maxLength30 = (value) => {
    if (value && value.length > 15) {
        return "Максимальное значение > 15";
    } else {
        return undefined;
    }
}
export const required = (value) => {
    if (value) {
        return undefined;
    } else {
        return "Поле не заполненно!"
    }
}
export const requiredCheckBox = (value) => {
    if (value) {
        return undefined;
    } else {
        return "Поле не заполненно!"
    }
}
export const maxLengthCreator = (maxLength) => (value) => {
    if (value.length > maxLength ) {
        return `Максимальное количество символов ${maxLength}`;
    } else {
        return  undefined;
    }
}

