import React from "react";
import styles from "./input.module.css";

export const Input = ({input, meta, ...props}) => {
    const hasError = meta.touched && meta.error;
    return (
        <div>
            <div className={styles.formControl + " " + (hasError ? styles.error : " ")}>
                <input {...input} {...props} />
            </div>
            <div className={styles.errorMessage}>
                {hasError && <span>{meta.error}</span>}
            </div>
        </div>
    )
}
export const CheckBox = ({input, meta, ...props}) => {
    const hasError = meta.touched && meta.error;
    return (
        <div className={styles.error_check}>
            <div
                className={styles.formControlE + " " + styles.formControlRigth + " " + (hasError ? styles.formControlErrr : " ")}>
                <input {...input} {...props} />
                <span> Я согласен с <span className={styles.rulesLink} onClick={props.onClick(true)}>условиями</span></span>
            </div>
            <div className={styles.errorMessage}> {hasError && <span>{meta.error}</span>}</div>
        </div>
    )
}
export const TextArea = ({input, meta, ...props}) => {
    const hasError = meta.touched && meta.error;
    return (
        <div>
            <div className={styles.formControl + " " + (hasError ? styles.error : " ")}>
                <textarea {...input} {...props} />
            </div>
            <div className={styles.errorMessage}>
                {hasError && <span>{meta.error}</span>}
            </div>
        </div>
    )
}