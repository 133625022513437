import React from "react";
import style from "./Loader.module.css";

class Loader extends React.Component{
    render() {
        return (
            <div className={style.lds_ripple}>
                <div></div>
                <div></div>
            </div>
        );
    }
}
export default Loader;