import React from "react";
import MetaTags from 'react-meta-tags';
import style from "./Project.module.css";
import {NavLink} from "react-router-dom";
import Loader from "../Elements/Loader/Loader";
import axios from "axios";
import HeaderContainerConnect from "../Header/HeaderContainer";
import Fade from 'react-reveal/Fade';
import FormHOC from "../Elements/Contact/ContactContainer";
//
import ContainerModal from "../Elements/Modal/ModalContainer";
import ContainerMenuModal from "../Elements/ModalMenu/ModalMenuContainer";


const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML }});

class Project extends React.Component {

    state = {
      projects_h1: '',
      projects_text: '',
      meta_title: '',
      meta_description: ''
    };

    componentDidMount() {
      window.scrollTo(0, 0);

      // выбираем текст блока "Знакомство с проектами" из админки WP
      axios.get("https://dzendom.su/api/wp-json/wp/v2/pages/403").then(response => {
        this.setState({
          projects_h1: response.data.title.rendered,
          projects_text: response.data.content.rendered
        });
      });

      // выбираем мета теги из админки WP
      axios.get("https://dzendom.su/api/wp-json/grandit/v1/meta-tags").then(response => {
        this.setState({
          meta_title: response.data.title,
          meta_description: response.data.description
        });
      });
    }

    filterByCat(cat) {
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=100&&categories=" + cat)
            .then(response => {
                this.props.setLoading(false);
                this.props.setProject(response.data);
                this.props.setLoading(true);
                this.props.setFilter(cat);
            });
    }

    getCategory() {
        return this.props.state.catrgories && this.props.state.catrgories.map(el => (
            <Fade key={el.id}><li className={this.props.activeFilter === el.id ? style.li__active : style.li} onClick={(e) => this.filterByCat(el.id)}>{el.count ? el.name : null}</li></Fade>
        ))
    }

    AllWorks() {
        return this.props.state.projects.map(el => {
            return (
                    <div className="col-lg-4 col-md-6 col-12 mb-2 pl-1 pr-1" key={el.id}>
                        <Fade>
                        <div className={style.hoder_div}>

                            <NavLink to={"projects/" + el.slug}><img className={style.img_full} src={el.fimg_url} alt=""/>
                                <div className={style.hover_block}>
                                    <h5 className="mb-1">
                                        {el.title.rendered}
                                    </h5>
                                    {el.acf.ploshhad_obekta.ploshhad_obekta_value.length > 0 &&
                                      <p className="mb-0">
                                          {el.acf.ploshhad_obekta.ploshhad_obekta_name} - {el.acf.ploshhad_obekta.ploshhad_obekta_value}
                                      </p>
                                    }
                                    {el.acf.kolichestvo_komnat.kolichestvo_komnat_value.length > 0 &&
                                    <p className="mb-0">
                                        {el.acf.kolichestvo_komnat.kolichestvo_komnat_name} - {el.acf.kolichestvo_komnat.kolichestvo_komnat_value}
                                    </p>
                                    }
                                    {el.acf.kolichestvo_etazhej.kolichestvo_etazhej_value.length > 0 &&
                                    <p className="mb-0">
                                        {el.acf.kolichestvo_etazhej.kolichestvo_etazhej_name} - {el.acf.kolichestvo_etazhej.kolichestvo_etazhej_value}
                                    </p>
                                    }
                                    {el.acf.status_proekta.status_proekta_value.length > 0 &&
                                        <p className="mb-0">{el.acf.status_proekta.status_proekta_name} - {el.acf.status_proekta.status_proekta_value}</p>
                                    }
                                    {el.acf.god_proektirovaniya.god_proektirovaniya_value.length > 0 &&
                                        <p className="mb-0">{el.acf.god_proektirovaniya.god_proektirovaniya_name} - {el.acf.god_proektirovaniya.god_proektirovaniya_value}</p>
                                    }
                                </div>
                            </NavLink>
                        </div>
                        </Fade>
                    </div>
            )
        })
    }

    render() {
        return (
            <div className={style.wrapper}>
                <MetaTags>
                  <title>{this.state.meta_title}</title>
                  <meta name="description" content={this.state.meta_description} />
                  <meta property="og:title" content={this.state.meta_title} />
                </MetaTags>
                <HeaderContainerConnect/>
                
                <div className={"container mt-4 " + style.before_text}>
                    <Fade><h1 className={style.h1}>{this.state.projects_h1}</h1></Fade>
                    <Fade><p className={style.text_excerpt}>{renderHTML(this.state.projects_text)}</p></Fade>
                    <Fade>
                        <div className="row mt-5">
                            <div className="col-12">
                                <ul className={style.ul_wrapper}>
                                    {this.getCategory()}
                                </ul>
                            </div>
                        </div>
                    </Fade>
                </div>
                
                {/**/}
                {this.props.showPopup ? <ContainerModal/> : null}
                <ContainerMenuModal/>

                <div className={style.all_works}>
                    <div className="row p-0 m-0">
                        {this.props.loading ? this.AllWorks() : <Loader/>}
                    </div>
                </div>

                <div className="container">
                        <div className="wrapper_cont">
                            <Fade><FormHOC/></Fade>
                        </div>
                </div>
            </div>

        )
    }
}

export default Project;