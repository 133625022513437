import {connect} from "react-redux";
import Modal from "./Modal";
import {setShowAc} from "../../../redux/indexReducers";


const mapStateToProps = (state) => {
    return {
        showPopup: state.indexReducer.showPopup
    }
}
let ContainerModal = connect(mapStateToProps, {setShowAc})(Modal);

export default ContainerModal;