import React from "react";
import SimpleSlider from "./SimpleSlider";
import axios from "axios";
import {connect} from "react-redux";
import {setSLIDERAc} from "../../../redux/SliderReducer";


class ContainerSimpleSlider extends React.Component {

    componentDidMount() {
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=6")
            .then(response => {
                this.props.setSLIDERAc(response.data);
            });
    }

    render() {
        return (
            <SimpleSlider state={this.props.state}/>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        state: state.SliderReducer.sliderPost
    }
}

const ConnectContainerSimpleSlider = connect(mapStateToProps, {setSLIDERAc})(ContainerSimpleSlider)
export default ConnectContainerSimpleSlider;