const SETMENU = "SET_MENU";
const SETEKO = "SET_EKO";
const SETARCH = "SET_ARCH";
/**/
const SETUYUT = "SET_UYUT";
const SETCOT = "SET_COT";

let initialState = {
    eko: null,
    archs: null,
    /**/
    uyut: null,
    cot: null,

    showMenu: false
};

const ModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETMENU :
            return  {
                ...state,
                showMenu: action.isShowed
            }
        case SETEKO :
            return  {
                ...state,
                eko: [...action.eko]
            }
        case SETARCH :
            return  {
                ...state,
                archs: [...action.archs]
            }
            /**/
        case SETUYUT :
            return  {
                ...state,
                uyut: [...action.uyut]
            }

        case SETCOT :
            return  {
                ...state,
                cot: [...action.cot]
            }

        default:
            return state
    }
}

export const setMenuAc = (isShowed) => {
    return {
        type: SETMENU,
        isShowed
    }
}
export const setEkoPosts = (eko) => {
    return {
        type: SETEKO,
        eko
    }
}
export const setArchPosts = (archs) => {
    return {
        type: SETARCH,
        archs
    }
}

/**/
export const setUyutPosts = (uyut) => {
    return {
        type: SETUYUT,
        uyut
    }
}

export const setCotPosts = (cot) => {
    return {
        type: SETCOT,
        cot
    }
}


export default ModalReducer;