import React from "react";
import logo from "../../assets/logo_b.svg";
import bar from "../../assets/bar.svg";
import style from "./HeaderSingle.module.css";
import { HashLink as Link } from 'react-router-hash-link';
import {NavLink} from "react-router-dom";
import ContainerMenuModal from "../Elements/ModalMenu/ModalMenuContainer";

class HeaderSingle extends React.Component {
    render() {
        return (
            <>
                <ContainerMenuModal/>
                <div className={style.wrapper_project}>
                    <div className={style.nav_fixed}>
                        <div className={style.logo_right}>
                            <NavLink to="/"><img src={logo} alt=""/></NavLink>
                        </div>
                    </div>
                    <div className={style.nav_fixed_right}>
                        <div>
                            <img onClick={() => this.props.setMenuAc(true)} className={style.logo} src={bar} alt=""/>
                        </div>
                        <div></div>
                    </div>
                    <div id="first_slide" className={style.first_slide}>
                        <div className="container">
                            <div className={style.navigation}>
                                <ul className={style.wrapper__links}>
                                    <li className={style.links}>
                                        <NavLink to="/">Главная</NavLink>
                                    </li>
                                    <li className={style.links}>
                                        <NavLink activeClassName="active_link_menu" className={style.active_link_menu_black} to="/project">Проекты</NavLink>
                                    </li>
                                    <li className={style.links}>
                                        <Link to="/#three_slide">Философия</Link>
                                    </li>
                                    <li className={style.links}>
                                        <Link to="/#footer">Контакты</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default HeaderSingle;