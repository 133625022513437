import React from "react";
import style from "../Mainpage/Mainpage.module.css";
import SocialList from "../Elements/SocialList/SocialListContainer.jsx";
import {NavLink} from "react-router-dom";
import logo from "../../assets/logo_white.svg";
import axios from "axios";
import ScrollToTop from "../Elements/ScrollToTop/ScrollToTop.jsx";

const renderHTML = (rawHTML: string) => React.createElement("div", {
  className: style.footer_text_right,
  dangerouslySetInnerHTML: { __html: rawHTML }
});

class Footer extends React.Component {

    state = {
      contacts_text: ''
    };

    componentDidMount() {
      // выбираем текст блока "Контакт" из админки WP
      axios.get("https://dzendom.su/api/wp-json/wp/v2/pages/374").then(response => {
        this.setState({contacts_text: response.data.content.rendered});
      });
    }

    render() {
        return (
            <>
                    <div id="contacts" className={style.footer}>
                        <ScrollToTop/>
                        <div id="footer__desktop" className="container">


                            <div className={style.footer_wrapper_text}>
                                <div className="row">
                                    <div className="col-lg-6 col-12 align-self-end">
                                        <NavLink to="/"><img height="70px" className="mb-3" src={logo} alt=""/></NavLink>
                                        <p className={style.footer_text_left}>Обращаем Ваше внимание на то, что данный
                                            интернет-сайт носит исключительно
                                            информационный
                                            характер и ни при каких условиях не является публичной офертой, определяемой
                                            положениями
                                            ч. 2 ст. 437 Гражданского кодекса Российской Федерации.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 align-self-end">
                                        <p className={style.footer_text_right + " " + style.text_right_top}>СВЯЗАТЬСЯ</p>
                                        {renderHTML(this.state.contacts_text)}
                                    </div>
                                </div>

                                <div className={style.bbb}/>


                                <div className="row">
                                    <div className="col-lg-7 col-12">
                                        <p className={style.copy_footer}>ДЗЕН ДОМ 2020 Все права защищены</p>
                                    </div>
                                    <div className="col-lg-5 col-12">
                                        <SocialList css={style.social_icons}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="mobile_footer" className="container">
                            <div className={style.footer_wrapper_text}>
                                <div className="row mt-4 mb-4">
                                    <div className="col-12">
                                        <NavLink to="/"><img className="mb-3" src={logo} alt=""/></NavLink>
                                        <div className="col-12">
                                            <div className="mt-3 mb-3"><p
                                                className={style.footer_text_right}>СВЯЗАТЬСЯ</p></div>
                                            <div className="mb-4 mt-4">
                                                <SocialList css={style.social_icons}/>
                                            </div>
                                            {renderHTML(this.state.footer_contacts_text)}
                                        </div>

                                        <div className="mt-4 mb-4">
                                            <p className={style.footer_text_left}>Обращаем Ваше внимание на то, что
                                                данный
                                                интернет-сайт носит исключительно
                                                информационный
                                                характер и ни при каких условиях не является публичной офертой,
                                                определяемой
                                                положениями
                                                ч. 2 ст. 437 Гражданского кодекса Российской Федерации.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={style.bbb}/>
                                <div className="row">
                                    <div className="col-lg-7 col-12">
                                        <p className={style.copy_footer}>ДЗЕН ДОМ 2020 Все права защищены</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        )
    }
}

export default Footer;