import React from "react";
import axios from "axios";
import {connect} from "react-redux";
import {setSocials} from "../../../redux/indexReducers";
import f from "../../../assets/image/Svg/F.svg";
import i from "../../../assets/image/Svg/I.svg";
import t from "../../../assets/image/Svg/T.svg";
import w from "../../../assets/image/Svg/W.svg";
import y from "../../../assets/image/Svg/Y.svg";

/**
 * Список ссылок в соц. сети, расположенный в футере и в левом выдвигающемся меню
 *
 * @type type
 */
class SocialListContainer extends React.Component {

  render() {
    return (
      <div className={this.props.css}>
        <a href={this.props.socials.fb}><img width="45px" src={f} alt=""/></a>
        <a href={this.props.socials.tw}><img width="45px" src={t} alt=""/></a>
        <a href={this.props.socials.viber}><img width="45px" src={w} alt=""/></a>
        <a href={this.props.socials.yt}><img width="45px" src={y} alt=""/></a>
        <a href={this.props.socials.in}><img width="45px" src={i} alt=""/></a>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
    return {
        socials: state.indexReducer.socials,
    }
}

const ConnectContainer = connect(mapStateToProps, {setSocials})(SocialListContainer)

export default ConnectContainer;