import React from "react";
import Slider from "react-slick";
import {NavLink} from "react-router-dom";
import {Fade} from "react-reveal";
import style from "./SimpleSlider.module.css";

class SimpleSlider extends React.Component {


    Projects() {
        let projectPost = this.props.state;
        return projectPost && projectPost.map(el => {
            return (
                <div className="col-12" key={el.id}>
                    <Fade>
                        <a href={"/projects/" + el.slug} className={style.wrapper_card_project}>
                            <img className="img-fluid" src={el.fimg_url} alt=""/>
                            <p className={style.title_card}>{el.title.rendered}</p>
                            <p className={style.small_info}>{el.acf.ploshhad_obekta.ploshhad_obekta_value} м.кв</p>
                            <div className={style.hover_wrapper}>
                                <div className={style.wrapper_card_ob}>
                                    <span className={style.hower_wrapper_span}>{el.acf.price}</span>
                                </div>
                            </div>
                        </a>
                    </Fade>
                </div>
            )
        })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="main_slider">
                <Slider {...settings}>
                    {this.Projects()}
                </Slider>
            </div>
        );
    }
}

export default SimpleSlider;