import React from "react";
import axios from "axios";

function getFaviconEl() {
  return document.getElementById("favicon");
}

function changeFavicon(src) {
  const favicon = getFaviconEl();
  favicon.href = src;

  let $favicon = document.createElement("link")
    $favicon.rel = "icon"
    $favicon.href = src
    $favicon.sizes = "32x32"
    document.head.appendChild($favicon)

  let $favicon2 = document.createElement("link")
    $favicon2.rel = "icon"
    $favicon2.href = src
    $favicon2.sizes = "192x192"
    document.head.appendChild($favicon2)

  let $favicon3 = document.createElement("link")
    $favicon3.rel = "apple-touch-icon-precomposed"
    $favicon3.href = src
    document.head.appendChild($favicon3)

  let $favicon4 = document.createElement("meta")
    $favicon4.name = "msapplication-TileImage"
    $favicon4.content = src
    document.head.appendChild($favicon4)
}

class Favicon extends React.Component {
  componentDidMount() {

    axios.get("https://dzendom.su/api/wp-json/grandit/v1/get-favicon/")
          .then(response => {
            changeFavicon(response.data);
          });

  }

  render() {
    return (null);
  }

}

export default Favicon;