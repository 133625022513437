import indexReducer from "./indexReducers";
import {combineReducers, createStore} from "redux";
import ProjectReducer from "./ProjectReducer";
import SingleReducer from "./SingleReducers";
import ModalReducer from "./ModalReducer";
import {reducer as formReducer} from "redux-form";
import SliderReducer from "./SliderReducer";

let reducers = combineReducers({
    indexReducer,
    ProjectReducer,
    SingleReducer,
    ModalReducer,
    SliderReducer,
    form: formReducer
});

let store = createStore(reducers);

window.store = store;

export default store;