const SET_SLIDER = "SET_SLIDER";

let initialState = {
    sliderPost: null
};

const SliderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SLIDER :
            return  {
                ...state,
                sliderPost: [...action.posts]
            }
        default:
            return state
    }
}

export const setSLIDERAc = (posts) => {
    return {
        type: SET_SLIDER,
        posts
    }
}


export default SliderReducer;