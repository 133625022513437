import React from "react";
import {connect} from "react-redux";
import ModalMenu from "./ModalMenu";
import {setArchPosts, setEkoPosts, setMenuAc, setUyutPosts, setCotPosts} from "../../../redux/ModalReducer";
import axios from "axios";
import {setFilter} from "../../../redux/ProjectReducer";
import {CSSTransition, TransitionGroup} from "react-transition-group";


class ModalMenuContainer extends React.Component {
    componentDidMount() {
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=5&&categories=12")
            .then(response => {
                this.props.setEkoPosts(response.data);
            });
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=5&&categories=14")
            .then(response => {
                this.props.setArchPosts(response.data);
            });

        /**/
        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=5&&categories=15")
            .then(response => {
                this.props.setUyutPosts(response.data);
            });

        axios.get("https://dzendom.su/api/wp-json/wp/v2/posts?per_page=5&&categories=16")
            .then(response => {
                this.props.setCotPosts(response.data);
            });
    }

    render() {
        return (
                    <ModalMenu showMenu={this.props.showMenu}
                               eko={this.props.eko}
                               arch={this.props.archs}
                               /**/
                               uyut={this.props.uyut}
                               cot={this.props.cot}
                               setMenuAc={this.props.setMenuAc}
                               setEkoPosts={this.props.setEkoPosts}
                               setArchPosts={this.props.setArchPosts}
                               /**/
                               setUyutPosts={this.props.setUyutPosts}
                               setCotPosts={this.props.setCotPosts}
                               setFilter={this.props.setFilter}
                    />
        );
    }
}


const mapStateToProps = (state) => {
    return {
        showMenu: state.ModalReducer.showMenu,
        eko: state.ModalReducer.eko,
        archs: state.ModalReducer.archs,
        /**/
        uyut: state.ModalReducer.uyut,
        cot: state.ModalReducer.cot,

    }
}
let ContainerMenuModal = connect(mapStateToProps, {
    setMenuAc,
    setEkoPosts,
    setArchPosts,
    /**/
    setUyutPosts,
    setCotPosts,
    setFilter
})(ModalMenuContainer);

export default ContainerMenuModal;